import { useQuery } from '@apollo/client';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';

const ManifestBatchData_Fragment = graphql(`
    fragment ManifestBatchData on BatchShipManifestRowDTO {
        practiceName
        orderIds
        orderNumbers
        shipSpeed
        primaryShelf
        subShelf
    }
`);

export const ManifestQuery = graphql(`
    query GetBatchShipManifest($manufacturerId: String!) {
        getBatchShipManifest(shippingManufacturerId: $manufacturerId) {
            batches {
                ...ManifestBatchData
            }
        }
    }
`);

export interface ManifestBatch {
    id: string; //id for react
    practiceName: string;
    orderCount: number; // generated on the frontend
    orderIds: string[];
    orderNumbers: string[];
    shipSpeed: string;
    primaryShelf: string;
    subShelf: string;
}

export function useManifestQuery(manufacturerId: string): {
    batches: ManifestBatch[];
    loading: boolean;
    refetch: () => void;
} {
    const { data, loading, refetch } = useQuery(ManifestQuery, {
        variables: { manufacturerId: manufacturerId },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
    const batches = getFragmentData(ManifestBatchData_Fragment, data?.getBatchShipManifest.batches) ?? null;

    return {
        loading,
        refetch,
        batches: (batches ?? []).map((batch, i): ManifestBatch => {
            return {
                id: `batch ${i}`,
                practiceName: batch.practiceName,
                orderCount: batch.orderIds.length,
                orderIds: batch.orderIds.map(id => {
                    return `${id}\n`;
                }),
                orderNumbers: batch.orderNumbers.map(orderNumber => {
                    return `${orderNumber}\n`;
                }),
                shipSpeed: batch.shipSpeed,
                primaryShelf: batch.primaryShelf,
                subShelf: batch.subShelf,
            };
        }),
    };
}
