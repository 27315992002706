import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import type { LabsGqlOrderMetadataForLabSlipDto } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';
import QRCode from 'react-qr-code';

const useStyles = stylesFactory(() => ({
    subSectionTitle: {
        marginTop: '0.25rem',
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    root: {
        border: `.15rem solid`,
        borderRadius: '6px',
        marginTop: '0.5rem',
        padding: '0.5rem',
    },
    item: {
        fontSize: '0.75rem !important',
        marginTop: '0.25rem',
    },
}));

// Renders the feedback text we display alongside the QR code. The text is dependent on the order metadata.
const FeedbackText: React.VFC<{ metadata: LabsGqlOrderMetadataForLabSlipDto | null }> = ({ metadata }) => {
    const classes = useStyles();

    if (
        metadata &&
        metadata.orderMetadataType === 'CrownContactsV1ControlGroup' &&
        metadata.creditAmountCents &&
        metadata.creditAmountCents > 0
    ) {
        return (
            <Text variant={'body1'} className={classes.item}>
                We value your feedback! Please scan the QR code to let us know how we did and{' '}
                <b>we will apply a {Format.currency(metadata.creditAmountCents, 'cents', true)} credit</b> to your
                order.
            </Text>
        );
    }

    return (
        <Text variant={'body1'} className={classes.item}>
            Scan the QR code to provide feedback on this order. All feedback you share will help our designers and lab
            technicians better understand your preferences and improve the quality of your restorations.
        </Text>
    );
};

/**
 * Renders the QR code for the feedback form with a prompt requesting feedback.
 * @param props
 * @returns QR code to render with prompt for feedback
 */
export const FeedbackQRCode: React.VFC<{
    order: LabsGqlLabOrderForLabSlipFragment;
}> = ({ order }) => {
    const classes = useStyles();

    return (
        <Grid container wrap={'nowrap'} justifyContent={'space-between'} className={classes.root}>
            <Grid item>
                <Text variant={'body1'} className={classes.subSectionTitle}>
                    We'd love to hear how things went.
                </Text>
                <FeedbackText metadata={order.order_metadata} />
            </Grid>
            <Grid item>
                <Grid container justifyContent={'flex-end'}>
                    <QRCode
                        value={`${OrthlyBrowserConfig.practicePortalUrl}/${PracticeScreen.feedback}/${order.id}`}
                        style={{ height: '4rem', width: '4rem', margin: '1rem' }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
