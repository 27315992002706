import type { IconName } from '@orthly/ui';
import { RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, useScreenIsMd, styled, Icon, Box } from '@orthly/ui-primitives';
import type { CSSProperties } from 'react';
import React from 'react';

export const PRACTICE_OUTAGE_BANNER_HEIGHT = 40;

const Banner = styled(Box, {
    shouldForwardProp: p => p !== 'leftAlign',
})<{ leftAlign?: boolean }>(({ leftAlign }) => ({
    height: PRACTICE_OUTAGE_BANNER_HEIGHT,
    display: 'flex',
    width: '100%',
    justifyContent: leftAlign ? 'start' : 'center',
    padding: '0 16px',
    alignItems: 'center',
}));

const StyledIcon = styled(Icon, {
    shouldForwardProp: p => p !== 'iconSize',
})<{ iconSize?: number }>(({ iconSize }) => ({
    width: iconSize ?? 24,
    height: iconSize ?? 24,
    marginRight: 8,
}));

const CTAButton = styled(Button)({
    marginLeft: 16,
    height: 32,
});

const BannerText = styled(Text)({
    overflow: 'hidden',
    textTruncate: 'ellipsis',
    whiteSpace: 'nowrap',
});

const BannerDialogText = styled(Text)({
    whiteSpace: 'pre-line',
    lineHeight: '1rem',
});

export interface PracticeBanner {
    title: string;
    description?: string;
    type: 'warning' | 'critical' | 'info' | 'suggest';
    leftAlign?: boolean;
}

interface PracticeBannerProps {
    banner: PracticeBanner | null;
}

interface BannerDisplayProps {
    backgroundColor: CSSProperties['backgroundColor'];
    icon: IconName;
    iconColor: string;
    iconSize?: number;
    maxTextWidth?: number;
    textColor?: CSSProperties['color'];
}

const getBannerDisplayProps = (banner: PracticeBanner): BannerDisplayProps => {
    if (banner.type === 'warning') {
        return {
            backgroundColor: FlossPalette.WARNING,
            icon: 'WarningIcon',
            iconColor: FlossPalette.BLACK,
            maxTextWidth: 596,
        };
    }

    if (banner.type === 'info') {
        return {
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            icon: 'InfoIcon',
            iconColor: FlossPalette.BLACK,
            iconSize: 18,
        };
    }

    if (banner.type === 'suggest') {
        return {
            backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
            icon: 'InfoIcon',
            iconColor: FlossPalette.SECONDARY_FOREGROUND,
            textColor: FlossPalette.SECONDARY_FOREGROUND,
            iconSize: 18,
        };
    }

    return {
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        icon: 'Error',
        iconColor: FlossPalette.ATTENTION_FOREGROUND,
    };
};

export const PracticeBanner: React.VFC<PracticeBannerProps> = ({ banner }) => {
    const screenIsMedium = useScreenIsMd();

    const [open, setOpen] = React.useState(false);

    if (!banner || screenIsMedium) {
        return null;
    }

    const { backgroundColor, icon, iconColor, iconSize, maxTextWidth, textColor } = getBannerDisplayProps(banner);

    return (
        <Banner leftAlign={banner.leftAlign} style={{ backgroundColor }} data-testid={`practice-banner-${banner.type}`}>
            <StyledIcon icon={icon} iconSize={iconSize} style={{ color: iconColor }} />
            <BannerText
                medium
                variant={'body2'}
                style={{ maxWidth: maxTextWidth, color: textColor ?? FlossPalette.BLACK }}
            >
                {banner.title}
            </BannerText>
            {banner.description && (
                <RootActionDialog
                    showCloseButton
                    open={open}
                    loading={false}
                    setOpen={setOpen}
                    title={banner.title}
                    content={<BannerDialogText variant={'body1'}>{banner.description}</BannerDialogText>}
                    CustomButton={() => (
                        <CTAButton variant={'secondary'} onClick={() => setOpen(true)}>
                            Learn More
                        </CTAButton>
                    )}
                />
            )}
        </Banner>
    );
};
