import { InputAdornment, TextField } from '@orthly/ui-primitives';
import AutoNumeric from 'autonumeric';
import React from 'react';

/**
 * CurrencyTextField is a [react](https://reactjs.org/) component with automated currency and number format, and with [Material-ui](https://material-ui.com/) look and feel.
 *
 * CurrencyTextField is a wrapper component for <a href="https://github.com/autoNumeric/autoNumeric">autonumeric</a> and based on <a href="https://github.com/mkg0/react-numeric">react-numeric</a>.
 *
 * Main features:
 * * Adds thousands separator automatically.
 * * Adds automatically the decimals on blur.
 * * Smart input. User can only type the accepted characters depending on the current value.
 * * Lots of config options...
 * * It accepts all the `props` and `classes` of Material-Ui <a href="https://material-ui.com/api/text-field/#textfield-api">TextField API</a> (Ex: classes, label, helperText, variant).
 * * And also all the `options` from <a href="http://autonumeric.org/guide">AutoNumeric</a>
 */

export class CurrencyTextField extends React.Component<any> {
    input: any;
    autonumeric?: AutoNumeric;

    constructor(props: any) {
        super(
            Object.assign(
                {
                    type: 'text',
                    variant: 'standard',
                    currencySymbol: '$',
                    outputFormat: 'number',
                    textAlign: 'right',
                    maximumValue: '10000000000000',
                    minimumValue: '-10000000000000',
                },
                props,
            ),
        );
        this.getValue = this.getValue.bind(this);
    }

    componentDidMount() {
        const { currencySymbol, ...others } = this.props;
        this.autonumeric = new AutoNumeric(this.input, this.props.value, {
            ...this.props.preDefined,
            ...others,
            onChange: undefined,
            onFocus: undefined,
            onBlur: undefined,
            onKeyPress: undefined,
            onKeyUp: undefined,
            onKeyDown: undefined,
            modifyValueOnWheel: false,
            watchExternalChanges: false,
        });
    }
    componentWillUnmount() {
        this.autonumeric?.remove();
    }

    UNSAFE_componentWillReceiveProps(newProps: any) {
        const isValueChanged = this.props.value !== newProps.value && this.getValue() !== newProps.value;

        if (isValueChanged) {
            this.autonumeric?.set(newProps.value);
        }
    }

    getValue() {
        if (!this.autonumeric) {
            return;
        }
        const valueMapper = {
            string: (numeric: AutoNumeric) => numeric.getNumericString(),
            number: (numeric: AutoNumeric) => numeric.getNumber(),
        };
        const outputFormat: 'string' | 'number' = this.props.outputFormat;
        return valueMapper[outputFormat](this.autonumeric);
    }

    render() {
        const {
            classes,
            currencySymbol,
            inputProps,
            InputProps,
            // ...others
        } = this.props;
        const otherProps: any = {};
        [
            'id',
            'label',
            'className',
            'autoFocus',
            'variant',
            'style',
            'error',
            'disabled',
            'type',
            'name',
            'defaultValue',
            // "value",
            'tabIndex',
            'fullWidth',
            'minRows',
            'maxRows',
            'select',
            'required',
            'helperText',
            'unselectable',
            'margin',
            'SelectProps',
            'multiline',
            'size',
            'FormHelperTextProps',
            'placeholder',
        ].forEach(prop => {
            return (otherProps[prop] = this.props[prop]);
        });

        return (
            <TextField
                inputRef={ref => (this.input = ref)}
                InputProps={{
                    startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>,
                    ...InputProps,
                }}
                inputProps={{
                    className: classes?.textField,
                    ...inputProps,
                }}
                {...otherProps}
                {...this.props.field}
                onChange={this.props.field.onChange}
            />
        );
    }
}
