import { useFirebaseStorage } from '../../context';
import type { UserRole } from '../../util';
import type { ToolStateSetter } from '../Annotations/DrawingControls';
import { INITIAL_TOOL_STATE } from '../Annotations/DrawingControls';
import { useDesignOrderRevisionsLoader } from '../DesignViewer/OrderDesign.hooks.graphql';
import type { CurrentWaxup } from './Waxups.types';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { TakeSnapshotRef, ToolState } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type {
    LabsGqlOrder,
    LabsGqlWaxupReviewRejectionFragment,
    LabsGqlWaxupReviewSubmissionFragment,
} from '@orthly/graphql-operations';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlLabOrderWaxupReviewStatus,
    LabsGqlOrderItemSkuType,
    LabsGqlWorkflowTaskType,
} from '@orthly/graphql-schema';
import { DesignStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import type Firebase from 'firebase/compat/app';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

export type WaxupSubmission = LabsGqlWaxupReviewRejectionFragment | LabsGqlWaxupReviewSubmissionFragment;

export interface OrderWaxupProps {
    order: LabsGqlOrder;
    userRole: UserRole;
    refetchOrder: () => Promise<any>;

    // loading is quite expensive in CPU, especially for large models. Without clickToLoad=true,
    // the user pays for it whether they care about the waxup or not
    clickToLoad?: boolean;
}

export const shouldShowWaxup = (order: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'hold_details'> | undefined) => {
    if (!order?.fulfillment_workflow.configuration.waxup_required) {
        return false;
    }

    return (
        order.hold_details?.hold_is_practice_managed_pause ||
        order.fulfillment_workflow.active_task?.__typename === 'WaxupReviewWorkflowTask' ||
        order.fulfillment_workflow.closed_tasks.some(task => task.type === LabsGqlWorkflowTaskType.WaxupReview)
    );
};

const VeneerWaxupUtilDesignOrderRevision_Fragment = graphql(`
    fragment VeneerWaxupUtilDesignOrderRevision_Fragment on DesignOrderDesignRevisionDTO {
        id
        is_latest_design
        source_file_zip_path
        doctor_review {
            status
        }
    }
`);

const getCurrentWaxupWithDesign = (
    slimDesignFragments: FragmentType<typeof VeneerWaxupUtilDesignOrderRevision_Fragment>[],
    order?: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'id' | 'waxup_status'>,
    selectedRevisionId?: string,
    internalEvaluation?: boolean,
): { currentDesignId: string } | undefined => {
    const slimDesigns = getFragmentData(VeneerWaxupUtilDesignOrderRevision_Fragment, slimDesignFragments);

    // Early return if no waxup status and not internal evaluation
    if (!order?.waxup_status && !internalEvaluation) {
        return undefined;
    }

    // Return selected design if it exists
    const selectedDesign = slimDesigns.find(design => design.id === selectedRevisionId);
    if (selectedDesign) {
        return { currentDesignId: selectedDesign.id };
    }

    // Return latest design if in WaxupReview
    if (order?.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview) {
        const latestDesign = slimDesigns.find(design => design.is_latest_design);
        if (latestDesign) {
            return { currentDesignId: latestDesign.id };
        }
    }

    // Return most recently approved design
    const approvedDesign = slimDesigns.find(
        design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved,
    );
    if (approvedDesign) {
        return { currentDesignId: approvedDesign.id };
    }

    // Return most recently rejected design
    const mostRecentlyRejectedDesign = slimDesigns.findLast(
        design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected,
    );
    if (mostRecentlyRejectedDesign) {
        return { currentDesignId: mostRecentlyRejectedDesign.id };
    }

    // No valid design found
    return undefined;
};

// Gets the design from the waxup that was rejected most recently prior to the currently selected waxup, if there is such a waxup.
const getMostRecentlyRejectedRevisionId = (
    slimDesignFragments: FragmentType<typeof VeneerWaxupUtilDesignOrderRevision_Fragment>[],
    order?: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'id'>,
    selectedRevisionId?: string,
): string | undefined => {
    if (!order || !order.fulfillment_workflow.configuration.waxup_required || !selectedRevisionId) {
        return undefined;
    }

    const slimDesigns = getFragmentData(VeneerWaxupUtilDesignOrderRevision_Fragment, slimDesignFragments);
    const rejectedDesigns = slimDesigns.filter(
        design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected,
    );

    if (!rejectedDesigns.length) {
        // There is no rejection history, so there is no previous waxup.
        return undefined;
    }

    const selectedRejectionIdx = rejectedDesigns.findIndex(design => design.id === selectedRevisionId);

    // Return the last rejection
    if (selectedRejectionIdx === -1) {
        return _.last(rejectedDesigns)?.id;
    }

    // Return the rejection before the current one
    return rejectedDesigns[selectedRejectionIdx - 1]?.id;
};

/*
 * A utility hook to fetch the current state of the waxup.
 * This will also handle loading the correct design revision.
 * Please do not call this other than in the root of your viewer state, or they will likely get out of sync.
 */
export function useCurrentWaxup(
    order?: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'id' | 'waxup_status'>,
    seedId?: string,
    internalEvaluation?: boolean,
): CurrentWaxup {
    const [selectedRevisionId, setSelectedRevisionId] = React.useState<string | undefined>(seedId);
    const [selectedComparisonRevisionId, setSelectedComparisonRevisionId] = React.useState<string | undefined>(
        undefined,
    );
    const {
        refetch,
        loadDesign,
        loadAndSelectDesign,
        loadedDesignsById,
        slimDesignFragments,
        loadAndSelectComparisonDesign,
        loading,
    } = useDesignOrderRevisionsLoader(order?.id, true, false);

    const mostRecentlyRejectedRevisionId = getMostRecentlyRejectedRevisionId(
        slimDesignFragments ?? [],
        order,
        selectedRevisionId,
    );

    const slimDesigns = getFragmentData(VeneerWaxupUtilDesignOrderRevision_Fragment, slimDesignFragments ?? []);

    const hasApproval = slimDesigns.some(
        design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved,
    );

    const viewableSlimDesignIds = slimDesigns
        .filter(d => {
            // If they've approved a design, only show the ones they've explicitly approved or rejected.
            if (hasApproval) {
                return !!d.doctor_review;
            }

            // IF they haven't yet approved a design, we show any that they've reviewed _plus_ the latest one.
            return d.is_latest_design || !!d.doctor_review;
        })
        .map(d => d.id);
    const viewableSlimDesignFragments = (slimDesignFragments ?? []).filter(d => viewableSlimDesignIds.includes(d.id));

    const currentWaxup = getCurrentWaxupWithDesign(
        viewableSlimDesignFragments ?? [],
        order,
        selectedRevisionId,
        internalEvaluation,
    );

    React.useEffect(() => {
        if (!selectedRevisionId && currentWaxup?.currentDesignId) {
            setSelectedRevisionId(currentWaxup.currentDesignId);
        }
    }, [selectedRevisionId, setSelectedRevisionId, currentWaxup]);

    // Whenever the selected design changes, we load it if it hasn't already been requested.
    // TODO: revisit this, as it's not ideal.
    React.useEffect(() => {
        if (!selectedRevisionId) {
            return;
        }

        void loadAndSelectDesign(selectedRevisionId);
    }, [loadAndSelectDesign, selectedRevisionId]);

    // Whenever the previous design changes, we load it if it hasn't already been requested.
    // TODO: revisit this, as it's not ideal.
    React.useEffect(() => {
        if (!mostRecentlyRejectedRevisionId) {
            return;
        }

        void loadDesign(mostRecentlyRejectedRevisionId);

        setSelectedComparisonRevisionId(existingComparisonId => existingComparisonId ?? mostRecentlyRejectedRevisionId);
    }, [loadDesign, mostRecentlyRejectedRevisionId, setSelectedComparisonRevisionId]);

    // Whenever the comparison design changes, we load it if it hasn't already been requested.
    // TODO: revisit this, as it's not ideal.
    React.useEffect(() => {
        if (!selectedComparisonRevisionId) {
            return;
        }

        void loadAndSelectComparisonDesign(selectedComparisonRevisionId);
    }, [loadAndSelectComparisonDesign, selectedComparisonRevisionId]);

    return {
        loading,
        selectedRevisionId,
        setSelectedRevisionId,
        refetch,
        currentDesignFragment: selectedRevisionId ? loadedDesignsById[selectedRevisionId] ?? undefined : undefined,
        mostRecentlyRejectedDesignFragment: mostRecentlyRejectedRevisionId
            ? loadedDesignsById[mostRecentlyRejectedRevisionId] ?? undefined
            : undefined,
        slimDesignFragments: viewableSlimDesignFragments,
        setComparisonRevisionId: setSelectedComparisonRevisionId,
        comparisonDesignFragment: selectedComparisonRevisionId
            ? loadedDesignsById[selectedComparisonRevisionId] ?? undefined
            : undefined,
    };
}

// This hook determines if we want to enter the guided waxup flow or not. Guided waxups are only
// available for orders where all of the items fall into one or more of these skus:
// crown, implay, inlay, model or full denture
export const useShouldShowGuidedWaxupFlow = (order?: Pick<LabsGqlOrder, 'items_v2'>) => {
    const itemsOk =
        order?.items_v2.every(
            item =>
                item.sku === LabsGqlOrderItemSkuType.Bridge ||
                item.sku === LabsGqlOrderItemSkuType.Crown ||
                item.sku === LabsGqlOrderItemSkuType.Implant ||
                item.sku === LabsGqlOrderItemSkuType.ImplantBridge ||
                item.sku === LabsGqlOrderItemSkuType.Inlay ||
                item.sku === LabsGqlOrderItemSkuType.Model ||
                item.sku === LabsGqlOrderItemSkuType.Other ||
                item.sku === LabsGqlOrderItemSkuType.Veneer ||
                item.sku === LabsGqlOrderItemSkuType.Waxup ||
                item.sku === LabsGqlOrderItemSkuType.Partial ||
                item.sku === LabsGqlOrderItemSkuType.Denture,
        ) && order?.items_v2.some(item => item.sku !== LabsGqlOrderItemSkuType.Other);
    return !!itemsOk;
};

export async function uploadAnnotatedImage(
    storage: Firebase.storage.Storage,
    storagePath: string,
    image: Blob,
): Promise<string> {
    return new Promise((resolve, reject) => {
        // TODO: EPDPLT-4635 - Firebase Storage Migration
        storage
            .ref(`${storagePath}/annotation-${new Date().toISOString()}.png`)
            .put(image)
            .then(
                snapshot => resolve(snapshot.ref.fullPath),
                error => reject(error),
            );
    });
}

export const useUploadCurrentAnnotatedImage = (takeSnapshot: () => Promise<Blob | null>, orderId: string) => {
    const { enqueueSnackbar } = useSnackbar();
    // TODO: EPDPLT-4635 - Firebase Storage Migration
    const storage = useFirebaseStorage();

    return React.useCallback(async (): Promise<string | null> => {
        try {
            const blob = await takeSnapshot();
            if (!blob) {
                return null;
            }
            const storagePathConfig = getFullStoragePath(
                OrthlyBrowserConfig.env,
                DesignStorageConfigs.designAnnotations,
                orderId,
            );
            return await uploadAnnotatedImage(storage, storagePathConfig.path, blob);
        } catch (error) {
            enqueueSnackbar(`Error uploading annotation: ${error}`, { variant: 'error' });
            throw error;
        }
    }, [takeSnapshot, orderId, storage, enqueueSnackbar]);
};

export const useGetCommonAnnotationState = () => {
    const takeSnapshotRef: TakeSnapshotRef = React.useRef();
    const [isAnnotationListModalOpen, setIsAnnotationListModalOpen] = React.useState(false);
    const [screenshotToAnnotate, setScreenshotToAnnotate] = React.useState<Blob | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [canvasSize, setCanvasSize] = React.useState<{ width: number; height: number }>({
        width: 400,
        height: 400,
    });

    // We need to memoize this because `URL.createObjectURL` returns a new result every time.
    const backgroundImageUrl = React.useMemo(
        () => screenshotToAnnotate && URL.createObjectURL(screenshotToAnnotate),
        [screenshotToAnnotate],
    );

    return {
        takeSnapshotRef,
        isAnnotationListModalOpen,
        setIsAnnotationListModalOpen,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        isUploading,
        setIsUploading,
        canvasSize,
        setCanvasSize,
        backgroundImageUrl,
    };
};

export const useGetAnnotationCanvasFunctions = (
    orderId: string,
    takeSnapshotRef: TakeSnapshotRef,
    screenshotToAnnotate: Blob | null,
    setScreenshotToAnnotate: (value: React.SetStateAction<Blob | null>) => void,
    clear: () => void,
    setToolState: (action: ToolState | ((prevState: ToolState) => ToolState)) => void,
    trackingSource?: DandyAnalyticsEventSchemaType['Order Annotation - Capture Screenshot Clicked']['source'],
    setShowAnnotationCanvas?: (value: React.SetStateAction<boolean>) => void,
) => {
    const handleTakeScreenshot = React.useCallback(async () => {
        if (trackingSource) {
            BrowserAnalyticsClientFactory.Instance?.track('Order Annotation - Capture Screenshot Clicked', {
                source: trackingSource,
                $groups: { order: orderId },
            });
        }
        takeSnapshotRef?.current && setScreenshotToAnnotate(await takeSnapshotRef.current());
    }, [takeSnapshotRef, orderId, trackingSource, setScreenshotToAnnotate]);

    // If `screenshotToAnnotate` is nullish, takes a screenshot to enter annotation
    // mode, then calls through to `setToolState`.
    const setToolStateWrapped: ToolStateSetter = React.useCallback(
        arg => {
            if (!screenshotToAnnotate) {
                clear();
                void handleTakeScreenshot();
            }
            setToolState(arg);
        },
        [screenshotToAnnotate, handleTakeScreenshot, setToolState, clear],
    );

    const closeDrawingCanvas = React.useCallback(() => {
        setScreenshotToAnnotate(null);
        setToolState(INITIAL_TOOL_STATE);
        setShowAnnotationCanvas?.(false);
    }, [setScreenshotToAnnotate, setToolState, setShowAnnotationCanvas]);

    return {
        handleTakeScreenshot,
        setToolStateWrapped,
        closeDrawingCanvas,
    };
};
