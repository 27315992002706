import { OrderDetailBlock, OrderDetailFactRow } from '../OrderDetails/OrderDetailBlock';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { AlignerPrimaryConcern, TITLE_BY_ALIGNER_PRIMARY_CONCERN } from './AlignersTypes';
import type {
    LabsGqlAlignerCaseFragment,
    LabsGqlLabOrderFragment,
    LabsGqlPendingAlignerCaseBiteConcernsFragment as AlignerCaseBiteConcerns,
    LabsGqlPendingAlignerCaseCrowdingResolutionFragment as AlignerCaseCrowdingResolution,
    LabsGqlPendingAlignerCaseInterproximalSpaceSizeFragment as AlignerCaseInterproximalSpaceSize,
} from '@orthly/graphql-operations';
import type { LabsGqlOrderPriceEntityDto, LabsGqlPendingAlignerCaseConcernMetadata } from '@orthly/graphql-schema';
import { LabsGqlBiteConcernOptions, LabsGqlCrowdingResolutionOption } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const getAdditionalConcernsReadable = (
    concern: string,
    concernsMetadata?: LabsGqlPendingAlignerCaseConcernMetadata[] | null,
): string => {
    const metadata = concernsMetadata?.find(metadata => metadata.concern === concern);
    const formattedConcern = _.startCase(concern);
    return metadata ? `${formattedConcern} (${metadata.metadata})` : formattedConcern;
};

const getAlignerCrowdingResolutionReadable = (crowding_resolution?: AlignerCaseCrowdingResolution | null): string => {
    if (!crowding_resolution) {
        return 'Unknown'; // this is for old orders from before the ability to record concerns rolled out
    }
    return (
        _.chain(crowding_resolution)
            .entries()
            .map(([method, option]) => option === LabsGqlCrowdingResolutionOption.OnlyIfNeeded && _.startCase(method))
            .compact()
            .join(', ')
            .value() || 'None'
    );
};

const getAlignerBiteConcernsReadable = (bite_concerns?: AlignerCaseBiteConcerns | null): string => {
    if (!bite_concerns) {
        return 'Unknown'; // this is for old orders from before the ability to record concerns rolled out
    }
    return (
        _.chain(bite_concerns)
            .entries()
            .map(([method, option]) => option === LabsGqlBiteConcernOptions.Improve && _.startCase(method))
            .compact()
            .join(', ')
            .value() || 'None'
    );
};

const getAlignerSpacingReadable = (interproximal_space_sizes: AlignerCaseInterproximalSpaceSize[]): string => {
    return (
        _.chain(interproximal_space_sizes)
            .map(({ size, space: { tooth1, tooth2 } }) => `${size} between ${tooth1} & ${tooth2}`)
            .join(', ')
            .value() || 'None'
    );
};

const FLEX_BASIS_LEFT = 240;

export const OrderDetailAlignerCaseBlock: React.VFC<{
    lab_order: LabsGqlLabOrderFragment;
    aligner_case: LabsGqlAlignerCaseFragment;
    actions?: React.ReactNode;
    is_psr: boolean;
    orderPrices: Pick<LabsGqlOrderPriceEntityDto, 'total_price_cents'>[];
}> = ({ lab_order, aligner_case, actions, is_psr, orderPrices }) => {
    const { value: enableReadFromOrderPriceEntity } = useFeatureFlag('enableReadFromOrderPriceEntity');
    const { dentist_amount_due_cents } = lab_order;

    const totalOrderAmountDue = enableReadFromOrderPriceEntity
        ? _.sumBy(orderPrices, op => op.total_price_cents ?? 0)
        : dentist_amount_due_cents ?? 0;

    const {
        concerns,
        aligner_arch,
        movement_restricted_teeth,
        attachment_restricted_teeth,
        extraction_teeth,
        aligner_treatment_area,
        interproximal_space_sizes,
        crowding_resolution,
        bite_concerns,
        aligner_notes,
    } = aligner_case;
    const is_mobile = useScreenIsMobileOrVerticalTablet();
    return (
        <OrderDetailBlock variant={'full'} title={'Case'} actions={actions} contentStyle={{ flexDirection: 'row' }}>
            <Grid
                item
                container
                direction={'column'}
                xs={is_mobile ? undefined : 6}
                style={is_mobile ? undefined : { paddingRight: 16 }}
            >
                {concerns && (
                    <OrderDetailFactRow
                        title={
                            concerns.primary_concern in AlignerPrimaryConcern
                                ? TITLE_BY_ALIGNER_PRIMARY_CONCERN[concerns.primary_concern as AlignerPrimaryConcern]
                                : concerns.primary_concern
                        }
                        value={concerns.primary_concern_severity}
                        flexBasisLeft={FLEX_BASIS_LEFT}
                        separator={!is_mobile}
                    />
                )}
                {concerns && (
                    <OrderDetailFactRow
                        wrapperProps={{ alignItems: 'flex-start', style: { marginTop: 4 } }}
                        title={'Other'}
                        valueStyle={{ whiteSpace: 'unset' }}
                        value={concerns.additional_concerns
                            .map(c => getAdditionalConcernsReadable(c, concerns.concerns_metadata))
                            .join(', ')}
                    />
                )}
                <OrderDetailFactRow
                    title={'Arch'}
                    value={aligner_arch}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
                <OrderDetailFactRow
                    title={'Teeth Selection'}
                    value={_.startCase(aligner_treatment_area)}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
                <OrderDetailFactRow
                    title={'Movement Restricted Teeth'}
                    value={movement_restricted_teeth.join(', ') || 'None'}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
                <OrderDetailFactRow
                    title={'Attachment Restricted Teeth'}
                    value={attachment_restricted_teeth.join(', ') || 'None'}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
            </Grid>
            <Grid
                container
                item
                direction={'column'}
                xs={is_mobile ? undefined : 6}
                style={is_mobile ? undefined : { paddingLeft: 16 }}
            >
                <OrderDetailFactRow
                    title={'Extractions'}
                    value={extraction_teeth.join(', ') || 'None'}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
                <OrderDetailFactRow
                    title={'Spacing'}
                    value={getAlignerSpacingReadable(interproximal_space_sizes)}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
                <OrderDetailFactRow
                    title={'Crowding Resolution'}
                    value={getAlignerCrowdingResolutionReadable(crowding_resolution)}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
                <OrderDetailFactRow
                    title={'Bite Concerns'}
                    value={getAlignerBiteConcernsReadable(bite_concerns)}
                    flexBasisLeft={FLEX_BASIS_LEFT}
                    separator={!is_mobile}
                />
                {aligner_notes && (
                    <OrderDetailFactRow
                        title={'Notes'}
                        wrapperProps={{ alignItems: 'flex-start', style: { marginTop: 4 } }}
                        value={aligner_notes}
                        valueStyle={{ whiteSpace: 'unset' }}
                        flexBasisLeft={FLEX_BASIS_LEFT}
                        separator={!is_mobile}
                    />
                )}
                {concerns?.additional_concerns_notes && (
                    <OrderDetailFactRow
                        title={'Notes'}
                        wrapperProps={{ alignItems: 'flex-start' }}
                        value={<span>{concerns.additional_concerns_notes}</span>}
                        valueStyle={{ whiteSpace: 'unset' }}
                        flexBasisLeft={FLEX_BASIS_LEFT}
                        separator={!is_mobile}
                    />
                )}
                {is_psr && (
                    <OrderDetailFactRow
                        title={'Practice Cost'}
                        value={Format.currency(totalOrderAmountDue)}
                        flexBasisLeft={FLEX_BASIS_LEFT}
                        separator={!is_mobile}
                    />
                )}
            </Grid>
        </OrderDetailBlock>
    );
};
